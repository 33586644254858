<template>
  <the-nav></the-nav>

  <div class="container">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: null,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.scss";
</style>
  