<template>
  <main class="wrapper">
    <section class="downloads-section">
      <div class="content-wrapper">
        <h1>A Bulk File Renamer</h1>
        <h3>Renaming made easy</h3>
        <h5>Free || Open-source</h5>
        <span class="base-button-container">
          <base-button :downloadLink="versionMapping['win32-x64']">
            <i class="bi bi-microsoft"></i>
            <!-- <i v-if="this.os === 'windows'" class="bi bi-microsoft"></i> -->
            <!-- <i v-if="this.os === 'linux'" class="bi bi-tencent-qq"></i>
            <i v-if="this.os === 'mac'" class="bi bi-apple"></i> -->
            <!-- Download for {{ this.os }} -->
            Download x64 Windows
          </base-button>
        </span>
        <p>
          Visit <router-link to="/downloads">here</router-link> for more
          downloads options
        </p>
        <p>
          By using Flexa, you agree to its
          <a :href="versionMapping['license']">license</a>
        </p>
      </div>
    </section>
    <section class="image-section">
      <img
        src="https://flexa.forcle.in/assets/banner.png"
        alt="banner"
        class="banner"
      />
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      versionMapping: {
        "win32-x64":
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FFlexa-1.1.1-win32-x64-PR%20(1).zip?alt=media&token=6aad6857-72b2-4193-86ed-c3d81aa89d39",
        "win32-ia32":
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FFlexa-1.1.1-win32-ia32-PR%20(1).zip?alt=media&token=25a28151-88e8-4817-955d-75be311d6788",
        license:
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FLICENSE?alt=media&token=fc66d110-46ac-401d-a6ee-528cf27566cf",
      },
    };
  },
  // methods: {
  //   async download(version) {
  //     const objectUrl = await fetch(versionMapping[version]);

  //     const link = document.createElement("a");
  //     link.setAttribute("href", objectUrl);
  //     link.setAttribute("download", version);
  //     link.style.display = "none";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   },
  //   checkSystemInfo() {
  //     if (this.userAgent.search("Mac") !== -1) {
  //       this.os = "mac";
  //     } else if (this.userAgent.search("Linux") !== -1) {
  //       this.os = "linux";
  //     } else if (this.userAgent.search("Windows") !== -1) {
  //       this.os = "windows";
  //     } else {
  //       this.os = "windows";
  //     }
  //   },
  // },
  // data() {
  //   return {
  //     userAgent: navigator.userAgent,
  //     os: null,
  //   };
  // },
  // mounted() {
  //   this.checkSystemInfo();
  // },
};
</script>

<style scoped lang="scss">
@import "../assets/styles/Home.scss";
</style>

