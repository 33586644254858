import { createRouter, createWebHistory } from 'vue-router';
import DocsPage from './pages/DocsPage.vue';
import DonatePage from './pages/DonatePage.vue';
import DownloadsPage from './pages/DownloadsPage.vue';
import HomePage from './pages/HomePage.vue';
import NotFound from './pages/errors/NotFound.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", component: HomePage },
        { path: "/docs", component: DocsPage },
        { path: "/downloads", component: DownloadsPage },
        { path: "/donate", component: DonatePage },
        { path: "/:notFound(.*)", component: NotFound }
    ]
});

export default router;
