<template>
  <main class="main semantics">
    <header>
      <select name="selectTopic" id="selectTopic" @change="scroller">
        <option value="">Select Topic</option>
        <option @click="scroller" value="whatIsFlexa">What is Flexa?</option>
        <option @click="scroller" value="howToInstall">How to install?</option>
        <option @click="scroller" value="howItWorks">How it works?</option>
        <option @click="scroller" value="choosingDirectory">Choosing directory</option>
        <option @click="scroller" value="choosingMode">Choosing mode</option>
        <option @click="scroller" value="choosingDelimiter">Choosing delimiter</option>
        <option @click="scroller" value="changingCase">Changing case</option>
        <option @click="scroller" value="trimming">Trimming</option>
        <option @click="scroller" value="applyingDate">Applying date</option>
        <option @click="scroller" value="applyingText">Applying text</option>
        <option @click="scroller" value="applyingSerialNumbers">Applying serial numbers</option>
        <option @click="scroller" value="howToIninstall">How to uninstall?</option>
      </select>
    </header>

    <aside class="sidebar">
      <ul class="index-list">
        <li class="index-item">
          <a href="#whatIsFlexa">What is Flexa?</a>
        </li>
        <li class="index-item">
          <a href="#howToInstall">How to install?</a>
        </li>
        <li class="index-item">
          <a href="#howItWorks">How it works?</a>
        </li>
        <li class="index-item">
          <a href="#choosingDirectory">Choosing directory</a>
        </li>
        <li class="index-item">
          <a href="#choosingMode">Choosing mode</a>
        </li>
        <li class="index-item">
          <a href="#choosingDelimiter">Choosing delimiter</a>
        </li>
        <li class="index-item">
          <a href="#changingCase">Changing case</a>
        </li>
        <li class="index-item">
          <a href="#trimming">Trimming</a>
        </li>
        <li class="index-item">
          <a href="#applyingDate">Applying date</a>
        </li>
        <li class="index-item">
          <a href="#applyingText">Applying text</a>
        </li>
        <li class="index-item">
          <a href="#applyingSerialNumbers">Applying serial numbers</a>
        </li>
        <li class="index-item">
          <a href="#howToIninstall">How to uninstall?</a>
        </li>
      </ul>
    </aside>

    <section id="documentation">
      <article class="content" id="whatIsFlexa">
        <h3>What is Flexa?</h3>
        <p>
          Flexa is a bulk file/folder renaming tool. which lets a User
          append/prepend Serial numbers, Date, or Text to multiple files/folders
          or change their case simultaneously. It also offers Users to trim the
          portion of a file/folder name that is not required.
        </p>
      </article>

      <article class="content" id="howToInstall">
        <h3>How to install?</h3>
        <p>
          You just have to unzip it in any folder of your choice after
          downloading it and click on the Application file to use it.
        </p>
        <p class="note-info hide">
          <b>Note: </b>
          Please download the correct architecture of this software, downloading
          incorrect architecture may cause technical issues. To check your
          System's architecture, <br />
          Go to Settings > Search for "About your PC" > You will see it in
          System type
        </p>
      </article>

      <article class="content" id="howItWorks">
        <h3>How it works?</h3>
        <p>
          After downloading and unzipping Flexa, you have to double click on the
          Application file to run it. To do that, head over to the folder where
          you have unzipped the zip file and find an Application file named
          "Flexa".
        </p>
        <p class="note-info">
          <b>Note: </b>
          After selecting or choosing any transformation, Flexa will directly
          rename your files on your computer.
        </p>
        <div class="step" id="choosingDirectory">
          <h3>1. Choosing a directory</h3>
          <p>
            To rename multiple files/folders, you must select a directory where
            they are kept in. To do that, click on the textbox at the top. All
            the files and folders in that directory will be visible in an area,
            underneath the textbox. <br />
            You will also be able to see some info like Total items, Total
            files, Total Folders, and Selected items.
          </p>
        </div>

        <div class="step" id="choosingMode">
          <h3>2. Choosing mode</h3>
          <p>
            There are two modes available in Flexa, prepend and append. Prepend
            mode is like prefixing something while Append is like suffixing
            something. If you choose Prepend then the transformations like Date,
            Text, and Serial numbers will start prefixing text, date, or serial
            numbers. While if you choose to Append then the transformations like
            Date, Text, and Serial numbers will start a suffixing text, date, or
            serial numbers. If you select both, it will do prefix and suffix.
            While if you do not select any the transformations will be
            prepended.
          </p>
        </div>

        <div class="step" id="choosingDelimiter">
          <h3>3. The Delimiter</h3>
          <p>
            The delimiter or separator is used to separate Date, Text, and
            Serial numbers in a file name. The default delimiter is a single
            space. <br />
            Example: If you wish to prepend serial number with the default
            delimiter, to a filename named "FileName.txt" then after
            transformation it would be "1 FileName.txt". <br />
            While if you specify an underscore (_) as a delimiter then after
            transformation it would be "1_FileName.txt". <br />
          </p>
        </div>

        <div class="step" id="changingCase">
          <h3>4. Changing case</h3>
          <p>
            You can change multiple file/folder names by using Change case
            transformation. It offers you multiple options like Upper case,
            Lower case, Capitalize case, Kebab case, and Snake case. <br />
            To change the case, select at least one file/folder and select the
            case from the dropdown. By selecting an option will directly change
            the file name in your file system.
          </p>
        </div>

        <div class="step" id="trimming">
          <h3>5. Trim</h3>
          <p>
            You can remove a part of a file/folder name that is not required
            using Trim functions. You can trim the file/folder name from the
            left or right-hand side. <br />
            Example: If you wish to get "FileName.txt" from "30-10-2021
            FileName.txt" then you can specify the Left trim function, by
            leaving Delimiter as blank. Or if you wish to get "FileName.txt"
            from "FileName_30-10-2021.txt" then you can specify underscore (_)
            as delimiter and use the Right trim function.
          </p>
        </div>

        <div class="step" id="applyingDate">
          <h3>6. Date</h3>
          <p>
            You can Append/Prepend any Date to the file/folder name. Just choose
            any date from the Date picker and click on the Apply button to
            rename it. By default, a single space is used to separate the Date
            and the original file name. You can specify the delimiter of your
            choice in the Delimiter's textbox.
          </p>
        </div>

        <div class="step" id="applyingText">
          <h3>7. Text</h3>
          <p>
            You can Append/Prepend any Text to the file/folder name. Just type
            any text in the textbox and click on the Apply button to rename it.
            By default, a single space is used to separate the Text and the
            original file name. You can specify the delimiter of your choice in
            the Delimiter's textbox.
          </p>
        </div>

        <div class="step" id="applyingSerialNumbers">
          <h3>8. Serial numbers</h3>
          <p>
            You can Append/Prepend Serial numbers to the file/folder name. Just
            type the number inside "Start with" and type the number inside
            "Increment by", by default the Serial number will start from 0 and
            will be incremented by 1. And by default, a single space is used to
            separate the Serial number and the original file name. You can
            specify the delimiter of your choice in the Delimiter's textbox.
          </p>
        </div>
      </article>

      <article class="content" id="howToIninstall">
        <h3>How to Uninstall?</h3>
        <p>
          Uninstallation is not required for Flexa, you just have to delete the
          folder that you have had unzipped.
        </p>
      </article>
    </section>
  </main>
</template>

<script>
export default {
  methods: {
    scroller(e) {
      let val = e.target.value;
      console.log(val);
      if ((val !== undefined || val !== null) && val.length > 0)
      {
        console.log(e);
        // document.getElementsByTagName("body")[0].scrollTop = e.target.offsetTop;
        document.querySelector(`#${val}`).scrollIntoView();
        console.log("Scrolled");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/styles/DocsPage.scss";
</style>

<!-- let mainNavLinks = document.querySelectorAll("nav ul li a");
let mainSections = document.querySelectorAll("main section");

let lastId;
let cur = [];

// This should probably be throttled.
// Especially because it triggers during smooth scrolling.
// https://lodash.com/docs/4.17.10#throttle
// You could do like...
// window.addEventListener("scroll", () => {
//    _.throttle(doThatStuff, 100);
// });
// Only not doing it here to keep this Pen dependency-free.

window.addEventListener("scroll", event => {
  let fromTop = window.scrollY;

  mainNavLinks.forEach(link => {
    let section = document.querySelector(link.hash);

    if (
      section.offsetTop <= fromTop &&
      section.offsetTop + section.offsetHeight > fromTop
    ) {
      link.classList.add("current");
    } else {
      link.classList.remove("current");
    }
  });
}); -->