<template>
  <nav>
    <p class="branding">
      <router-link to="/">
        <p class="brand">Forcle</p>
        <span class="small">&#169; 2022 Forcle</span>
      </router-link>
    </p>

    <ul class="navbar">
      <li>
        <router-link to="/docs">Privacy statement</router-link>
      </li>
      <li>
        <router-link to="/downloads">Terms & Condition</router-link>
      </li>
      <li>
        <router-link to="/donate">License</router-link>
      </li>
    </ul>
  </nav>
</template>

<!-- <style lang="scss" scoped>
@import "../../assets/styles/BaseStyles.scss";

.navbar-logo {
  width: 32px;
}

.branding {
  display: flex;
  gap: 0.2rem;
  font-size: large;
}

nav,
ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $gap;
}
nav {
  background-color: hsla(0, 0%, 7%, 100%);
  padding: 1rem;
  width: 100%;
  flex-flow: wrap;
}

.brand {
  font-size: larger;
}

.small {
  font-size: smaller;
}

.hamburger-menu {
  display: none;
}

@media screen and (min-width: 1200px) {
  nav {
    padding: 1rem;
  }
}
</style> -->