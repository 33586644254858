<template>
  <header class="wrapper">
    <nav class="navbar">
      <router-link to="/" class="navbar-brand">Flexa</router-link>

      <i class="bi bi-list hamburger-menu" @click="toggleNavbar"></i>

      <ul class="navbar-nav" v-if="navbarVisiblity">
        <li>
          <router-link to="/" class="navbar-brand" @click="hideNavbar">
            Flexa
          </router-link>
          <span @click="hideNavbar">&times;</span>
        </li>

        <li>
          <router-link to="/downloads" @click="hideNavbar">
            Downloads
          </router-link>
        </li>

        <li>
          <router-link to="/docs" @click="hideNavbar"> Docs </router-link>
        </li>

        <li v-if="false">
          <router-link to="/donate" @click="hideNavbar"> Donate </router-link>
        </li>
        <li>
          <a href="https://forcle.in" @click="hideNavbar"> Author </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      currentPage: "/",
      navbarVisiblity: false,
    };
  },
  methods: {
    setActive(routeToCheck) {
      if (!routeToCheck) return;
      const currentRoute = this.$route.path;
      return routeToCheck === currentRoute ? true : false;
    },
    toggleNavbar() {
      this.navbarVisiblity = !this.navbarVisiblity;
    },
    hideNavbar() {
      if (window.innerWidth <= 768) {
        this.navbarVisiblity = false;
      }
    },
  },
  created() {
    this.navbarVisiblity = false;
    if (window.innerWidth > 768) {
      this.navbarVisiblity = true;
    }
  },
};
</script>

<style lang="scss" scoped >
@import "../../assets/styles/NavbarStyle.scss";
</style>