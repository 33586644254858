<template>
  <div class="wrapper">
    <h1>Download Flexa</h1>
    <h2>A free bulk-file renaming tool.</h2>

    <section class="card-container">
      <div class="windows-card card">
        <!-- <i class="bi bi-microsoft"></i> -->
        <img src="../assets/windows_144.png" alt="windowss" />
        <!-- <base-button> Download 32 bit. </base-button>
        <base-button> Download 64 bit. </base-button> -->
        <div class="group">
          <a :href="versionMapping['win32-ia32']">32 bit</a>
          <a :href="versionMapping['win32-x64']">64 bit</a>
        </div>
      </div>
      <div class="linux-card card" v-if="!true">
        <!-- <i class="bi bi-tencent-qq"></i> -->
        <img src="../assets/linux_144.png" alt="windowss" />
        <base-button> Download for Ubuntu </base-button>
      </div>
    </section>
    <p>
      By downloading Flexa, you agree to its
      <a :href="versionMapping['license']">License</a>.
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      versionMapping: {
        "win32-x64":
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FFlexa-1.1.1-win32-x64-PR%20(1).zip?alt=media&token=6aad6857-72b2-4193-86ed-c3d81aa89d39",
        "win32-ia32":
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FFlexa-1.1.1-win32-ia32-PR%20(1).zip?alt=media&token=25a28151-88e8-4817-955d-75be311d6788",
        license:
          "https://firebasestorage.googleapis.com/v0/b/my-portfolio-db0aa.appspot.com/o/flexav1.1.1%2FLICENSE?alt=media&token=fc66d110-46ac-401d-a6ee-528cf27566cf",
      },
    };
  },
};
</script>
<style scoped lang="scss">
@import "../assets/styles/Downloads.scss";
</style>