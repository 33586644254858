import { createApp } from 'vue';
import App from './App.vue';

import router from './router.js';
import TheNav from './components/base/TheNav.vue';
import TheFooter from './components/base/TheFooter.vue';
import BaseButton from './components/base/BaseButton.vue';

const app = createApp(App);

app.component('the-nav', TheNav);
app.component('the-footer', TheFooter);
app.component('base-button', BaseButton);
app.use(router);

app.mount('#app');
