<template>
  <a :href="setDownloadLink">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: ["downloadLink"],
  computed: {
    setDownloadLink() {
      return this.downloadLink;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/_variables.scss";

a {
  display: inline-block;
  padding: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: larger;
  background-color: $primary;
  color: $white;
}

a:focus {
  box-shadow: 0 0 5px $primary;
}

a:active {
  transform: translate(0.5px, 0.5px);
}
</style>